import React, { useRef, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress } from 'viem';

const CreateEvents = ({ provider }) => {
  const eventNameRef = useRef();
  const eventDateRef = useRef();
  const eventLocationRef = useRef();
  const eventDescriptionRef = useRef();
  const ticketPriceRef = useRef();
  const availableTicketsRef = useRef();
  const eventCoverImageRef = useRef();
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));  // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  const generateUniqueId = async () => {
    const querySnapshot = await getDocs(collection(db, 'events'));
    const eventIds = querySnapshot.docs.map(doc => doc.data().id);
    let newId = 1;
    while (eventIds.includes(newId)) {
      newId += 1;
    }
    return newId;
  };

  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress, // Normalize the case of the address
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const createEvent = async (name, date, location, description, price, tickets, coverImage, address) => {
    try {
      if (!name || !date || !location || !description || !tickets || !price || !coverImage || !address) {
        toast.error('Please fill in all fields');
        return;
      }
      toast.info('Saving event...');

      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      const eventId = await generateUniqueId();

      const eventData = {
        id: eventId,
        name,
        date,
        location,
        description,
        price,
        tickets,
        coverImage,
        address,
      };

      await addDoc(collection(db, 'events'), eventData);

      toast.success('Event created successfully!');
      window.location.href = '/dapp/organizers/manage';
    } catch (error) {
      console.error('Error creating event:', error);
      if (error.message === 'Ethereum provider not available') {
        toast.error('Ethereum provider not available. Please ensure your wallet is connected.');
      } else {
        toast.error('Failed to create event.');
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Create Event</h2>
        <div className="flex flex-col gap-4">
          <input
            ref={eventNameRef}
            type="text"
            placeholder="Event Name"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={eventDateRef}
            type="date"
            placeholder="Event Date"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={eventLocationRef}
            type="text"
            placeholder="Event Location"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <textarea
            ref={eventDescriptionRef}
            placeholder="Event Description"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={ticketPriceRef}
            type="number"
            placeholder="Ticket Price (in USD)"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={availableTicketsRef}
            type="number"
            placeholder="Available Tickets"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={eventCoverImageRef}
            type="text"
            placeholder="Event Cover Image URL"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            type="text"
            placeholder="Wallet Address"
            value={userAddress}
            readOnly
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
        </div>
        <button
          className="px-4 py-2 bg-green-600 rounded-lg text-white mt-4"
          onClick={() =>
            createEvent(
              eventNameRef.current.value,
              eventDateRef.current.value,
              eventLocationRef.current.value,
              eventDescriptionRef.current.value,
              ticketPriceRef.current.value,
              availableTicketsRef.current.value,
              eventCoverImageRef.current.value,
              userAddress
            )
          }
        >
          Create Event
        </button>
      </div>
    </div>
  );
};

export default CreateEvents;
