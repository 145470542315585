import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { checksumAddress } from 'viem';

const ActiveSubscriptions = ({ provider }) => {
  const [userAddress, setUserAddress] = useState('');
  const [purchasedEvents, setPurchasedEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(accounts[0])); // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    if (!userAddress) return;

    const fetchPurchasedEvents = async () => {
      setIsLoading(true);
      try {
        const q = query(collection(db, 'events'), where('purchases', 'array-contains', { address: userAddress }));
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPurchasedEvents(eventsList);
      } catch (error) {
        console.error('Error fetching purchased events:', error);
        toast.error('Failed to fetch purchased events');
      }
      setIsLoading(false);
    };

    fetchPurchasedEvents();
  }, [userAddress]);

  if (!provider) {
    return toast.error('Please connect your wallet to view your subscriptions.')
  }

  if (isLoading) {
    return toast.info('Loading...')
  }

  const setReminder = (event) => {
    const reminderUrl = `https://www.remind-me-service.com/create?title=${encodeURIComponent(event.name)}&date=${encodeURIComponent(event.date)}`;
    window.open(reminderUrl, '_blank');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h1 className="text-3xl font-bold mb-6">My Tickets</h1>
      {purchasedEvents.length === 0 ? (
        <p>No tickets purchased yet.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {purchasedEvents.map(event => (
            <div key={event.id} className="bg-gray-800 p-4 rounded-lg">
              <h2 className="text-xl font-bold">{event.name}</h2>
              <p className="mb-4">{event.description}</p>
              <p className="text-sm text-gray-400">Date: {event.date}</p>
              <button
                onClick={() => setReminder(event)}
                className="mt-4 px-4 py-2 bg-indigo-600 rounded-lg text-white"
              >
                Set Reminder
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActiveSubscriptions;
